import { useTheme } from '@mui/material';
import React from 'react';
import { Chip } from 'sharedComponents/Chip';

export interface TabbedJobViewChip {
  dataTestId: string;
  label: string;
}

export const TabbedJobViewChip: React.FC<TabbedJobViewChip> = ({ dataTestId, label }) => {
  const theme = useTheme();

  return (
    <Chip
      label={label}
      variant="filled"
      dataTestId={dataTestId}
      sx={{
        backgroundColor: theme.palette.success.fill,
        color: theme.palette.primary.main,
        fontSize: theme.typography.body2.fontSize,
        fontWeight: theme.typography.fontWeightBold,
      }}
    />
  );
};
