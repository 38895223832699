import { Box, styled, Typography, useTheme } from '@mui/material';
import LoadingSpinner from 'components/loadingSpinner';
import { Alert } from 'sharedComponents/Alert';
import { getAxiosErrorMessageByStatusCode } from 'hooks/errorHandling/errorHandlingHooks';
import { useQueryGetHiringTeamData } from './queries';
import { ATSTabbedJobViewHiringTabTestIds } from 'data-testids/ATS';
import { SectionTitle } from '../styles';

const HiringTeam: React.FC<{ jobId: number }> = ({ jobId }) => {
  const theme = useTheme();

  return (
    <>
      <SectionTitle
        variant="h2"
        sx={{ marginBottom: theme.spacing(4), color: theme.palette.gray.dark }}
        data-testid={ATSTabbedJobViewHiringTabTestIds.HIRING_TEAM_SECTION_TITLE}
      >
        Hiring team for this job
      </SectionTitle>
      <HiringTeamDisplay jobId={jobId} />
    </>
  );
};

const HiringTeamDisplay: React.FC<{ jobId: number }> = ({ jobId }) => {
  const { data, isLoading, isError, error } = useQueryGetHiringTeamData(jobId);
  const errorMessage = getAxiosErrorMessageByStatusCode(error);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return (
      <Alert type="error" dataTestId={ATSTabbedJobViewHiringTabTestIds.HIRING_TEAM_ALERT}>
        {errorMessage ?? 'Something went wrong'}
      </Alert>
    );
  }

  return (
    <DisplayContainer>
      <HiringTeamUserList label="District Users" users={data.district_users} />
      <HiringTeamUserList label="Job Owners" users={data.job_owners} />
    </DisplayContainer>
  );
};

const HiringTeamUserList: React.FC<{ label: string; users: string[] }> = ({ label, users }) => {
  const userDisplay = users.length === 0 ? 'None' : users.join(', ');

  return (
    <UserListContainer>
      <UserListLabel data-testid={ATSTabbedJobViewHiringTabTestIds.HIRING_TEAM_USER_TYPE_LABEL}>
        {label}
      </UserListLabel>
      <UserList data-testid={ATSTabbedJobViewHiringTabTestIds.HIRING_TEAM_USER_DISPLAY}>
        {userDisplay}
      </UserList>
    </UserListContainer>
  );
};

export default HiringTeam;

const UserListContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(1),
}));

const UserListLabel = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  fontWeight: theme.typography.fontWeightMediumBold,
  color: theme.palette.gray.dark,
}));

const UserList = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.gray.dark,
}));

const DisplayContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));
