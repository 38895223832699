import axios, { AxiosError } from 'axios';
import { HiringTeamData } from '../types';
import { useQuery } from '@tanstack/react-query';

export const useQueryGetHiringTeamData = (jobId: number) => {
  const queryFn = async () => {
    const response = await axios.get<HiringTeamData>(`/api/roles/${jobId}/team/`);
    return response.data;
  };

  return useQuery<HiringTeamData, AxiosError>({
    queryKey: ['hiringTeam', jobId],
    queryFn: queryFn,
    refetchOnWindowFocus: false,
    enabled: Number.isInteger(jobId),
  });
};
