import { Grid, useTheme } from '@mui/material';
import { MultiSelectWithSearch, mapOptionsToValues } from 'sharedComponents/Select';
import { FilterContainerProps } from './types';
import { ConnectJobDiscoveryFiltersDataTestIds } from 'data-testids/ConnectDataTestIds';
import { useMemo } from 'react';

export const Filters: React.FC<FilterContainerProps> = ({
  filterValues,
  filterOptions,
  setSelectedGradeOptions,
  setSelectedSubjectOptions,
  setSelectedDistrictOptions,
  activeFilters,
}) => {
  const theme = useTheme();

  const gradeValues = useMemo(() => {
    return mapOptionsToValues(filterOptions.gradeOptions, filterValues.grades);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues.grades), filterOptions.gradeOptions.length]);

  const subjectValues = useMemo(() => {
    return mapOptionsToValues(filterOptions.subjectOptions, filterValues.subcategories);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues.grades), filterOptions.subjectOptions.length]);

  const districtValues = useMemo(() => {
    return mapOptionsToValues(filterOptions.districtOptions, filterValues.district_nces_ids);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(filterValues.district_nces_ids), filterOptions.districtOptions.length]);

  return (
    <Grid container spacing={1} rowGap={0.5} direction={{ xs: 'column', md: 'row' }} wrap="nowrap">
      <Grid item xs={12} md={4}>
        <MultiSelectWithSearch
          displayName="Grade Levels"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.GRADE_FILTER}
          handleChange={setSelectedGradeOptions}
          options={filterOptions.gradeOptions}
          values={gradeValues}
          tagLimit={0}
          size="small"
          sx={selectStyles(theme, activeFilters.grades)}
          getLimitTagsText={(more) => `${more} Grade(s) selected`}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <MultiSelectWithSearch
          displayName="Subjects"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.SUBJECTS_FILTER}
          handleChange={setSelectedSubjectOptions}
          options={filterOptions.subjectOptions}
          values={subjectValues}
          tagLimit={0}
          size="small"
          sx={selectStyles(theme, activeFilters.subcategories)}
          getLimitTagsText={(more) => `${more} Subject(s) selected`}
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <MultiSelectWithSearch
          displayName="Districts"
          dataTestId={ConnectJobDiscoveryFiltersDataTestIds.DISTRICT_FILTER}
          handleChange={setSelectedDistrictOptions}
          options={filterOptions.districtOptions}
          values={districtValues}
          tagLimit={0}
          size="small"
          sx={selectStyles(theme, activeFilters.district_nces_ids)}
          getLimitTagsText={(more) => `${more} District(s) selected`}
        />
      </Grid>
    </Grid>
  );
};

const selectStyles = (theme, optionSelected = false) => ({
  width: '100%',
  color: optionSelected ? theme.palette.primary.medium : 'auto',
  '& .MuiOutlinedInput-root': {
    borderRadius: theme.shape.borderRadius * 0.5,
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiOutlinedInput-notchedOutline': {
    border: optionSelected ? `2px solid ${theme.palette.primary.medium}` : 'auto',
  },
  '& .MuiInputLabel-root': {
    color: optionSelected ? theme.palette.primary.medium : 'auto',
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.body1.fontSize,
    },
  },
  '& .MuiInputBase-input': {
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.subtitle1.fontSize,
      height: theme.spacing(2.5),
    },
  },
});
