import { styled, Theme, useMediaQuery } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Stack from '@mui/material/Stack';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createButtonUrl } from 'features/Connect/features/ExpressInterest/utils';
import { useEffect, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { SecondaryButton, TextButton } from 'sharedComponents/Buttons';
import { CONNECT_JOBBOARD_STATES, NAVBAR_BUTTON_LABELS } from 'utils/constants';
import NimbleMobileLogo from '../../assets/nimble-navbar-v2-logo-mobile.png';
import NimbleLogo from '../../assets/nimble-navbar-v2-logo.svg';
import VerticalDivider from '../../assets/divider-vertical.png';
import auth from '../../utils/auth';

import { FunctionType } from './types';
import { NavbarV2DataTestIds } from './constants';
import { useTheme } from '@mui/material';
import { getConnectUrlPath, getStateLabel } from 'features/Connect/features/JobDiscovery/utils';
import { connectRedirectUrls } from 'features/Connect/features/JobDiscovery/constants';
import { useStateParam } from 'features/Connect/features/ConnectStateCodeContextProvider';
import { AuthenticatedAdminModal } from 'features/Connect/components/AuthenticatedAdminModal/AuthenticatedAdminModal';
import { nimbleConnectUrlFlag } from 'utils/constants';
import { userTypeToId } from 'utils/typedEnums';

export const NavbarV2: React.FC = () => {
  const [userAnchorElement, setUserAnchorElement] = useState<null | HTMLElement>(null);
  const [user, setUser] = useState(null);
  const [adminConnectExperienceModalIsOpen, setAdminConnectExperienceModalIsOpen] = useState(false);

  const history = useHistory();
  const isOnConnect = useRouteMatch({ path: `/${nimbleConnectUrlFlag}` });
  const connectLogin = useRouteMatch({ path: '/connect/login', exact: true });
  const connectSignup = useRouteMatch({ path: '/connect/signup', exact: true });
  const jobboardState = useStateParam();
  const connectUrlPath = getConnectUrlPath(jobboardState);
  const theme = useTheme();
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'));
  const isSmallTablet = useMediaQuery((theme: Theme) => theme.breakpoints.down('mdlg'));

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setUserAnchorElement(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setUserAnchorElement(null);
  };

  const handleLoginRedirect = () => {
    history.push(
      createButtonUrl({
        basePath: connectRedirectUrls.LOGIN,
        jobboardState: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
      })
    );
  };

  const handleLogout = () => {
    const url = createButtonUrl({ basePath: connectUrlPath });
    auth.logout().then(() => window.location.assign(url));
  };

  const handleProfileRedirect = () => {
    if (isOnConnect && user?.profile?.user_type !== userTypeToId.candidate) {
      setAdminConnectExperienceModalIsOpen(true);
    } else {
      const url = createButtonUrl({
        basePath: connectRedirectUrls.PROFILE,
        jobboardState: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
      });
      history.push(url);
    }
  };

  const handleSignUpRedirect = () => {
    history.push(
      createButtonUrl({
        basePath: connectRedirectUrls.SIGN_UP,
        jobboardState: CONNECT_JOBBOARD_STATES[jobboardState].urlParam,
      })
    );
  };

  const getNavbarLogo = () => {
    let logoToShow;
    if (jobboardState && CONNECT_JOBBOARD_STATES[jobboardState]?.logo) {
      logoToShow = CONNECT_JOBBOARD_STATES[jobboardState]?.logo;
    } else if (isMobile) {
      logoToShow = NimbleMobileLogo;
    } else {
      logoToShow = NimbleLogo;
    }
    return logoToShow;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const userInfo = auth.getUser();
    if (userInfo !== undefined) {
      setUser(userInfo);
    }
  });

  const settings = [
    { label: 'Profile', menuItemAction: handleProfileRedirect },
    { label: 'Logout', menuItemAction: handleLogout },
  ];

  const contactSupportOnClickHandler = () => {
    window.location.href = 'mailto:connectsupport@hirenimble.com';
  };

  return (
    <NavbarAppBar>
      <AuthenticatedAdminModal
        isOpen={adminConnectExperienceModalIsOpen}
        jobboardState={jobboardState}
        district={user?.profile?.district}
        onClose={() => setAdminConnectExperienceModalIsOpen(false)}
      />
      <NavbarContainer maxWidth={!connectLogin && !connectSignup ? '1130px' : 'auto'}>
        <NavbarToolbar disableGutters>
          <LogoAndTitleContainer direction={'row'} gap={2} alignContent={'center'}>
            <LogoLink href={connectUrlPath}>
              {isMobile && jobboardState && CONNECT_JOBBOARD_STATES[jobboardState]?.logo ? (
                <StateDOEMobileLogoContainer
                  src={getNavbarLogo()}
                  sx={{ pr: 1 }}
                  alt="nimbleLogo"
                />
              ) : jobboardState && CONNECT_JOBBOARD_STATES[jobboardState]?.logo ? (
                <StateDOELogoContainer src={getNavbarLogo()} alt="nimbleLogo" />
              ) : isMobile ? (
                <NimbleMobileLogoContainer src={getNavbarLogo()} sx={{ mr: 1 }} alt="nimbleLogo" />
              ) : (
                <NimbleLogoContainer src={getNavbarLogo()} sx={{ mr: 1 }} alt="nimbleLogo" />
              )}
            </LogoLink>
            {!(isMobile || isSmallTablet) && !!CONNECT_JOBBOARD_STATES[jobboardState] && (
              <>
                <img src={VerticalDivider} />
                <Stack direction={'column'} alignSelf={'center'}>
                  <JobSearchTitle>{`Official Educator Job Board`}</JobSearchTitle>
                  <JobSearchTitle>{`for the State of ${getStateLabel(jobboardState)}`}</JobSearchTitle>
                </Stack>
              </>
            )}
          </LogoAndTitleContainer>
          <Stack direction={'row'} gap={1}>
            {!user && (
              <>
                {!connectSignup && (
                  <SecondaryButton
                    sx={signupButtonStyles(theme)}
                    onClick={handleSignUpRedirect}
                    dataTestId={NavbarV2DataTestIds.CONNECT_SIGN_UP_BUTTON_DATA_TEST_ID}
                  >
                    {NAVBAR_BUTTON_LABELS.SIGN_UP}
                  </SecondaryButton>
                )}
                {!connectLogin && (
                  <TextButton onClick={handleLoginRedirect} sx={secondaryButtonStyles}>
                    {NAVBAR_BUTTON_LABELS.LOGIN}
                  </TextButton>
                )}
              </>
            )}
            <TextButton
              onClick={contactSupportOnClickHandler}
              sx={{
                fontSize: isMobile ? '14px' : '16px',
                fontStyle: 'normal',
                fontWeight: 600,
                color: theme.palette.text.secondary,
              }}
            >
              {isMobile
                ? NAVBAR_BUTTON_LABELS.MOBILE_SUPPORT
                : NAVBAR_BUTTON_LABELS.DESKTOP_SUPPORT}
            </TextButton>
            {user && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={user?.first_name} src="placeholder" />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: '45px' }}
                  id="menu-appbar"
                  anchorEl={userAnchorElement}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                  open={Boolean(userAnchorElement)}
                  onClose={handleCloseUserMenu}
                >
                  {settings.map((setting) => (
                    <MenuItem key={setting.label} onClick={handleCloseUserMenu}>
                      <Typography
                        textAlign="center"
                        onClick={() => handleMenuItemClick(setting.menuItemAction)}
                      >
                        {setting.label}
                      </Typography>
                    </MenuItem>
                  ))}
                  {user.is_hijacked && (
                    <MenuItem
                      onClick={() => {
                        auth.handleReleaseHijack();
                      }}
                    >
                      <span>Release Hijack</span>
                    </MenuItem>
                  )}
                </Menu>
              </Box>
            )}
          </Stack>
        </NavbarToolbar>
      </NavbarContainer>
    </NavbarAppBar>
  );
};

const NavbarAppBar = styled(AppBar)(({ theme }) => ({
  position: 'fixed',
  top: 0,
  background: 'white',
  height: '80px',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  boxShadow: 'none',
  borderBottom: '1px solid #EAECF0',
  padding: '0px 48px',

  [theme.breakpoints.down('lg')]: {
    height: '64px',
    padding: '0px 16px',
  },
}));

const NavbarContainer = styled(Box)(() => ({
  justifyContent: 'space-between',
  width: '100%',
}));

const NavbarToolbar = styled(Toolbar)(() => ({
  width: '100%',
  justifyContent: 'space-between',
  justifySelf: 'center',
}));

const LogoAndTitleContainer = styled(Stack)(() => ({
  height: '56px',
}));

const LogoLink = styled(Link)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const NimbleLogoContainer = styled('img')(() => ({
  display: 'flex',
  width: '132.656px',
  height: '36.129px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
}));

const NimbleMobileLogoContainer = styled('img')(() => ({
  display: 'flex',
  width: '36.129px',
  height: '36.129px',
  justifyContent: 'center',
  alignItems: 'center',
  color: 'white',
}));

const StateDOELogoContainer = styled('img')(() => ({
  display: 'flex',
  maxHeight: '46px',
  justifyContent: 'center',
  alignItems: 'center',
}));

const StateDOEMobileLogoContainer = styled('img')(() => ({
  maxHeight: '46px',
  maxWidth: '126px',
}));

const JobSearchTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightRegular,
  fontSize: theme.typography.body2.fontSize,
  color: theme.palette.text.tertiary,
  lineHeight: '24px',
}));

const signupButtonStyles = (theme: Theme) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontStyle: 'normal',
  fontSize: '16px',
  boxShadow: 'none',
  borderRadius: theme.shape.borderRadius * 0.5,
  color: theme.palette.text.secondary,
  borderColor: theme.palette.gray.mediumLight,

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
});

const secondaryButtonStyles = (theme: Theme) => ({
  fontSize: '16px',
  fontWeight: theme.typography.fontWeightBold,
  color: theme.palette.text.secondary,

  [theme.breakpoints.down('sm')]: {
    fontSize: '14px',
  },
});

const handleMenuItemClick = (menuItemAction: string | FunctionType) => {
  if (typeof menuItemAction === 'function') {
    menuItemAction();
  } else if (typeof window[menuItemAction] === 'function') {
    window[menuItemAction]();
  }
};
