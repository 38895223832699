import { Box, styled } from '@mui/material';
import HiringTeam from './HiringTeam';

const HiringTeamTab: React.FC<{ jobId: number }> = ({ jobId }) => {
  return (
    <TabContainer>
      <HiringTeam jobId={jobId} />
    </TabContainer>
  );
};

export default HiringTeamTab;

const TabContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(5),
}));
