import { Box, Divider, styled, Tab, Tabs } from '@mui/material';
import React, { useState } from 'react';
import { QuestionsTab } from './QuestionsTab/QuestionsTab';
import { VacanciesTab } from './VacanciesTab/VacanciesTab';
import HiringTeamTab from './HiringTeamTab';
import InternalNotesTab from './InternalNotesTab';
import auth from 'utils/auth';
import { JobPostingTab } from './JobPostingTab/JobPostingTab';
import { useParams } from 'react-router';
import { SetUpTab } from './SetUpTab/SetUpTab';

type JobviewTabData = {
  value: number;
  label:
    | 'Job Posting'
    | 'Questions'
    | 'Vacancies'
    | 'Job Set-up'
    | 'Hiring Team'
    | 'Internal Notes';
  content: React.ReactNode;
  isVisible: boolean;
};

const JobViewTabs: React.FC = () => {
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const { id } = useParams<{ id: string }>();
  const jobId = Number(id);

  const tabData: JobviewTabData[] = [
    { value: 0, label: 'Job Posting', content: <JobPostingTab jobId={jobId} />, isVisible: true },
    { value: 1, label: 'Questions', content: <QuestionsTab jobId={jobId} />, isVisible: true },
    {
      value: 2,
      label: 'Vacancies',
      content: <VacanciesTab jobId={jobId} />,
      isVisible: auth.isDistrictAdmin(),
    },
    { value: 3, label: 'Job Set-up', content: <SetUpTab jobId={jobId} />, isVisible: true },
    { value: 4, label: 'Hiring Team', content: <HiringTeamTab jobId={jobId} />, isVisible: true },
  ];

  const internalNotesTabData: JobviewTabData = {
    value: 5,
    label: 'Internal Notes',
    content: <InternalNotesTab jobId={jobId} />,
    isVisible: true,
  };

  const handleTabChange = (_: React.SyntheticEvent, newValue: number): void => {
    setSelectedTab(newValue);
  };

  return (
    <Container>
      <TabsContainer>
        <Tabs value={selectedTab} onChange={handleTabChange} orientation="vertical">
          {tabData.map((tab) => (
            <CustomTab
              key={tab.value}
              value={tab.value}
              label={tab.label}
              sx={{ display: !tab.isVisible && 'none' }}
            />
          ))}
          <Divider sx={{ display: !internalNotesTabData.isVisible && 'none' }} />
          <CustomTab
            key={internalNotesTabData.value}
            value={internalNotesTabData.value}
            label={internalNotesTabData.label}
            sx={{ display: !internalNotesTabData.isVisible && 'none' }}
          />
        </Tabs>
      </TabsContainer>
      {[...tabData, internalNotesTabData].map((tab) => (
        <TabContentContainer key={tab.value} display={selectedTab === tab.value ? 'block' : 'none'}>
          {tab.content}
        </TabContentContainer>
      ))}
    </Container>
  );
};
export default JobViewTabs;

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.contrastText,
  display: 'flex',
  flexDirection: 'row',
  height: '100%',
  padding: theme.spacing(4),
  width: '100%',
}));

const TabsContainer = styled(Box)(({ theme }) => ({
  width: '137px',
  marginTop: theme.spacing(3),
}));

const TabContentContainer = styled(Box)(({ theme }) => ({
  height: '100%',
  marginBottom: theme.spacing(1),
  marginLeft: theme.spacing(8),
  marginTop: theme.spacing(5),
  maxWidth: theme.breakpoints.values.lg,
  width: '100%',
}));

const CustomTab = styled(Tab)(({ theme }) => ({
  alignItems: 'start',
  paddingLeft: theme.spacing(1.5),
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  fontWeight: theme.typography.fontWeightMediumBold,
  fontSize: theme.typography.body2.fontSize,
}));
